import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import { IPerformance } from 'types/schedule';
import Button from '../ui/Button';
import ExploreButton from '../ui/ExploreButton';
import FooterPage from '../layout/FooterPage';

interface Props {
	backgroundImgSrc: string;
	pageImgSrc: string;
	contentSections: any[];
	transitionStatus: any;
}

function About({ backgroundImgSrc, pageImgSrc, contentSections = [], transitionStatus }: Props) {
	const [items, setItems] = useState<IPerformance[]>([]);
	const { scroll } = useLocomotiveScroll();
	const [imgScale, setImgScale] = useState(1);

	useEffect(() => {
		if (scroll === null) {
			return;
		}

		setTimeout(() => {
			scroll.destroy();
			scroll.init();

			// scroll.on('scroll', (args: any) => {
			// 	const { scroll } = args;
			// 	setImgScale(1.3 - scroll.y / 10000);
			// });
		}, 1000);
	}, [scroll]);

	useEffect(() => {
		const date = moment().format();

		const filteredData = data.allWordpressWpPerformance.edges.filter((x: any) => {
			const itemDate = moment(x.node.acf.dates[0].date_time).format();

			return itemDate > date;
		});

		const sortedData = filteredData.sort((a: any, b: any) => {
			return (new Date(a.node.acf.dates[0].date_time) as any) - (new Date(b.node.acf.dates[0].date_time) as any);
		});

		setItems(
			sortedData.map((x: any, i: any) => {
				const { node } = x;
				return (
					{
						id: node.id,
						key: i,
						title: node.title,
						url: `${node.acf.url}`,
						dateTime: node.acf.dates[0].date_time,
						location: node.acf.dates[0].location,
						imgSrc: node.featured_media ? node.featured_media.localFile.childImageSharp.fluid.src : undefined
					}
				);
			}
			));
	}, []);

	const data = useStaticQuery(graphql`
        {
            allWordpressWpPerformance {
                edges {
                    node {
                        id
                        title
                        slug
                        acf {
                            dates {
                                date_time
								location
                            }
							url
                        }
						featured_media {
							localFile {
								childImageSharp {
									fluid(maxWidth: 2880, quality: 100) {
										src
									}
								}
							}
						}
                    }
                }
            }
        }
    `);

	return (
		<>
			<section id="about-page" className="about-page section--fluid" data-scroll-section data-bg-colour="black">
				<div className="section-title" data-scroll data-scroll-speed="1" data-scroll-direction="vertical">
					<h2>About</h2>
				</div>
				<div className="about-page__bg">
					<div className={`animate inner${transitionStatus ? ` inner--${transitionStatus}` : ''}`} style={{ backgroundImage: `url('${backgroundImgSrc}')` }} />
					<ExploreButton selector="#content" />
				</div>
				<div className="container" id="content">
					{contentSections.length !== 0 && contentSections.map((x, i) => {
						return (
							<div style={{ width: '100%' }}>
								<div key={`${x.quote.author.replace(/\s/g, '')}_${Math.floor(Math.random() * 10000)}`} className="about-page__content-section">
									<div className="about-page__quote">
										<blockquote>“... {x.quote.text} ...”</blockquote>
										<cite>— {x.quote.author}</cite>
									</div>
									<div className="about-page__content" dangerouslySetInnerHTML={{ __html: x.content }} />
								</div>
								{i === 0 && (
									<div key={`${x.quote.author.replace(/\s/g, '')}_${Math.floor(Math.random() * 10000)}`}>
										<div className="upcoming-scroller">
											<h3 className="upcoming-scroller__title">Upcoming concerts</h3>
											<div className="upcoming-scroller__inner">
												<div
													data-scroll
													data-scroll-direction="horizontal"
													data-scroll-speed="2"
													className="upcoming-scroller__track"
												>
													<Swiper
														slidesPerView="auto"
														freeMode={true}
													>
														{items.length > 0 && items.map((x, i) => {
															if (i > 5) {
																return;
															}

															return (
																<SwiperSlide key={x.url}>
																	<article key={x.id} className="performance">
																		<div className="performance__inner">
																			<h3 className="performance__title">
																				{x.url ? <Button url={x.url}>{x.title}</Button> : x.title}
																			</h3>
																			<p className="performance__date">{x.dateTime}</p>
																		</div>
																	</article>
																</SwiperSlide>
															)
														})}
													</Swiper>
												</div>
											</div>
										</div>
										<div className="about-page__image">
											<div className="inner">
												<img
													src={pageImgSrc}
													alt=""
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						);
					})}
				</div>
			</section>
			<FooterPage />
		</>
	);
}

export default About;
